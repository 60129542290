<template>
  <div class="form-group">
    <label
      :style="`flex-basis: ${labelWidth}%`"
      v-if="label"
      :for="generatedId"
    >
      {{ label }}
    </label>
    <div
      class="form-group__input-container"
      :style="`flex-basis: ${inputWidth}%;`"
    >
      <select
        @input="updateValue"
        :value="modelValue"
        tabindex="0"
        :name="name"
        :id="generatedId"
      >
        <option
          :value="option.id ? option.id : option"
          v-for="(option, index) in options"
          :disabled="disabled"
          :key="index"
        >
          {{ option.text ? option.text : option }}
        </option>
      </select>
      <label class="form-group__arrow" :for="generatedId"></label>
      <errors :field="name" :errors="errors" />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import Errors from "../../Technical/Errors.vue";
import { useStore } from "vuex";
export default {
  name: "custom-select",
  components: { Errors },
  props: {
    modelValue: {
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    disabled: {
      default: false,
    },
    labelWidth: {
      type: Number,
      default: 25,
    },
    inputWidth: {
      type: Number,
      default: 75,
    },
  },
  setup(props, context) {
    const errors = computed(() => store.state.errors);
    const store = useStore();
    const generatedId = ref("select");
    const updateValue = (event) => {
      context.emit("update:modelValue", event.target.value);
    };

    const generateId = () => {
      let alphabet = "123456abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        generatedId.value +=
          alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
    };

    onBeforeMount(() => {
      generateId();
    });

    return {
      updateValue,
      errors,
      generateId,
      generatedId,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  margin: 10px -5px;
  font-size: 15px;
  font-weight: 400;
  justify-content: center;

  label:not(.form-group__arrow) {
    padding: 8px 15px 9px 15px;
    flex: 0 0 25%;
  }

  &__arrow::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-size: 25px;
    font-weight: 200;
    line-height: 1.7;
    color: #878787;
    width: 30px;
    height: 38px;
    position: absolute;
    right: 15px;
    top: 0;
    pointer-events: none;
  }

  &__input-container {
    flex: 0 1 75%;
    align-self: center;
    padding-right: 15px;
    position: relative;

    select {
      width: 100%;
      border: 2px solid #ebebeb;
      border-radius: 0;
      background-color: #fff;
      padding: 0 15px;
      height: 38px;
      line-height: 2;
      color: #878787;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    select:focus {
      border-color: var(--focus-color);
    }
  }

  @media (max-width: 768px) {
    margin: 0 -15px;
  }

  @media (max-width: 973px) {
    flex-direction: column;
    align-items: flex-start;

    label {
      flex: 1;
      padding: 15px;
    }
    &__input-container {
      padding-left: 15px;
      width: 100%;
      flex: 1;
    }
  }
}
</style>
