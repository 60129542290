<template>
  <div class="form-group">
    <label
      v-if="label"
      :for="generatedId"
      :style="`flex-basis: ${labelWidth}%;`"
    >
      {{ label }}
    </label>
    <div
      class="form-group__input-container"
      :style="`flex-basis: ${inputWidth}%;`"
    >
      <select2
        v-model="selectedValue"
        :id="generatedId"
        tabindex="0"
        @select="updateValue"
        class="form-control"
        :error="name"
        :name="name"
        :options="options"
        :settings="{
          multiple,
          tags: tagging,
          placeholder,
          allowClear,
          minimumResultsForSearch: minResultSearch,
          minimumInputLength,
          language: translate,
          ...settings,
        }"
        :disabled="disabled"
      ></select2>
      <errors :field="name" :errors="errors" />
    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onMounted,
  ref,
  toRef,
  watch,
} from "@vue/runtime-core";
import Errors from "../../Technical/Errors.vue";
import { useStore } from "vuex";
export default {
  name: "custom-input",
  components: { Errors },
  props: {
    modelValue: {
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: null,
    },
    multiple: {
      default: false,
    },
    tagging: {
      default: false,
    },
    disabled: {
      default: false,
    },
    minResultSearch: {
      type: Number,
      default: 7,
    },
    minimumInputLength: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
    },
    settings: {
      type: Object,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    inputWidth: {
      type: Number,
      default: 75,
    },
    labelWidth: {
      type: Number,
      default: 25,
    },
    id: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    let errors = computed(() => store.state.errors);
    const store = useStore();
    const modelValue = toRef(props, "modelValue");
    const id = toRef(props, "id");
    const generatedId = ref("select2"),
      selectedValue = ref(null);
    const updateValue = () => {
      context.emit("update:modelValue", selectedValue.value);
    };

    //Определяет какие окончания у слова в переводе в зависимости от числтительного. Пример: 1 чат[] (one), 2 чат[а] (couple), 25 чатов (more)
    function ending(count, one, couple, more) {
      if (
        (count % 10 < 5 && count % 10 > 0 && count % 100 < 5) ||
        count % 100 > 20
      ) {
        if (count % 10 > 1) {
          return couple;
        }
      } else {
        return more;
      }

      return one;
    }

    const translate = {
      errorLoading: function () {
        return "Невозможно загрузить результаты";
      },
      inputTooLong: function (args) {
        let overChars = args.input.length - args.maximum;
        let message = "Пожалуйста, введите на " + overChars + " символ";
        message += ending(overChars, "", "a", "ов");
        message += " меньше";
        return message;
      },
      inputTooShort: function (args) {
        let remainingChars = args.minimum - args.input.length;
        let message =
          "Пожалуйста, введите ещё хотя бы " + remainingChars + " символ";
        message += ending(remainingChars, "", "a", "ов");
        return message;
      },
      loadingMore: function () {
        return "Загрузка данных…";
      },
      maximumSelected: function (args) {
        let message = "Вы можете выбрать не более " + args.maximum + " элемент";
        message += ending(args.maximum, "", "a", "ов");
        return message;
      },
      noResults: function () {
        return "Совпадений не найдено";
      },
      searching: function () {
        return "Поиск…";
      },
      removeAllItems: function () {
        return "Удалить все элементы";
      },
    };

    const generateId = () => {
      if (id.value) {
        generatedId.value = id.value;
        return;
      }
      let alphabet = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        generatedId.value +=
          alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
    };

    onBeforeMount(() => {
      generateId();
      selectedValue.value = modelValue.value;
    });

    watch(
      () => modelValue.value,
      () => {
        selectedValue.value = modelValue.value;
      }
    );

    return {
      updateValue,
      errors,
      generateId,
      generatedId,
      selectedValue,
      translate,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  margin: 10px -5px;
  font-size: 15px;
  font-weight: 400;
  justify-content: center;

  label {
    padding: 8px 15px 9px 15px;
    flex: 0 0 25%;
    // word-break: break-word;
  }

  &__input-container {
    flex: 0 1 75%;
    align-self: center;
    padding-right: 15px;

    .form-control {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    margin: 0 -5px;
  }

  @media (max-width: 973px) {
    flex-direction: column;
    align-items: flex-start;

    label {
      flex: 1;
      padding: 15px 5px;
    }
    &__input-container {
      padding: 0 5px;
      width: 100%;
      // flex: 1;
    }
    .form-control {
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.select2-container--default {
  max-width: 100% !important;
  flex: 1;
  line-height: 1;
  font-size: 15px;
  color: #878787;
  .select2-selection--single {
    height: 2.7rem;

    .select2-selection__rendered {
      padding: 0 15px;
      height: 38px;
      line-height: 2;
      display: inline-block;
      align-items: center;
      color: #878787;
      width: 92%;
      white-space: normal;
      padding-top: 4px;
    }

    .select2-selection__arrow {
      width: 30px;
      height: 38px;
      b {
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        margin: 0;
        border: 0;
      }

      b::after {
        content: "\f107";
        font-family: "Font Awesome 5 Pro";
        font-size: 25px;
        font-weight: 200;
        line-height: 1.7;
        color: #878787;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--single .select2-selection__arrow {
      b::after {
        content: "\f106";
      }
    }
  }

  &.select2-container--focus {
    .select2-selection--multiple {
      border: 2px solid #ebebeb;
    }
  }

  .select2-selection--multiple {
    .select2-selection__rendered {
      padding: 4px 1.3rem;
      min-height: 42px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        max-width: 480px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .select2-selection__choice {
      border-radius: 0;
      background-color: #f2f2f2;
      color: #6e6e6e;
      border-width: 0;
      padding: 4px 6px;
      line-height: 18px;
      margin: 2px 5px 2px 0;
    }

    .select2-selection__choice__remove {
      color: #7a7a7a;
      margin-right: 3px;
      padding: 5px;
    }

    .select2-search__field {
      margin-top: 0 !important;
    }
  }

  .select2-search {
    input::placeholder {
      color: #d3d3d3;
    }
  }

  .select2-selection--single,
  .select2-selection--multiple {
    border: 2px solid #ebebeb;
    border-radius: 0;
    background-color: #fff;
  }

  .select2-dropdown {
    border: 2px solid #ebebeb;
    border-top: none;

    .select2-search--dropdown {
      border-bottom: 2px solid #ebebeb;
    }

    .select2-search__field {
      background-color: transparent;
      border-width: 0;
      outline: 0;
    }

    .select2-results__option {
      padding: 10px 6px;

      .select2-results__group {
        font-size: 12px;
        color: #6e6e6e;
      }
    }
    .select2-results__option--highlighted[aria-selected] {
      background-color: #2cc185;
    }
  }
}
</style>