<template>
  <div class="settings_wrapper">
    <div class="card-body">
      <div class="options_wrapper">
        <div class="settings_group">
          <div class="form-group">
            <custom-input
                name="intervals"
                label="Частота"
                type="number"
                :input-width="50"
                :label-width="50"
                :minValue="1"
                :maxValue="10"
                v-model="periodicity"
            />
          </div>
          <div class="popper_wrapper">
            <Popper placement="bottom" arrow class="products-popper">
              <div class="info_block">
                <img src="@/assets/images/icons/info.svg" alt="Информация" width="20" height="20"/>
              </div>
              <template #content>
                <span> Сколько раз в день вы хотите отмечать своё эмоциональное состояние</span>
              </template>
            </Popper>
          </div>
        </div>
        <div class="settings_group">
          <div class="form-group intervals">
            <custom-select-2
                name="intervals"
                label="Интервалы"
                :options="intervals"
                :multiple="true"
                v-model="intervalsForm"
                v-on:update:modelValue="checkIntervalsAndPeriodicity"
            />
          </div>
          <div class="popper_wrapper">
            <Popper placement="bottom-start" arrow class="products-popper">
              <div class="info_block">
                <img src="@/assets/images/icons/info.svg" alt="Информация" width="20" height="20"/>
              </div>
              <template #content>
                <span>В эти интервалы времени вы будете отмечать своё эмоциональное состояние</span>
              </template>
            </Popper>
          </div>
        </div>
      </div>
    </div>
    <div class="button_wrapper">
      <button @click="submitForm" :disabled="disabled" class="btn btn-accent">
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import {computed} from "@vue/reactivity";
import {useStore} from "vuex";
import {onBeforeMount, ref} from "@vue/runtime-core";
import {notify} from "@kyvg/vue3-notification";

export default {
  name: "DiarySettings",
  components: {CustomInput, CustomSelect2},
  setup(props, context) {
    const store = useStore();
    const settings = computed(() => store.state.diary.diarySettings);
    const disabled = ref(false);

    const intervals = [
      '00:00-00:30', '00:30-01:00', '01:00-01:30', '01:30-02:00', '02:00-02:30', '02:30-03:00',
      '03:00-03:30', '03:30-04:00', '04:00-04:30', '04:30-05:00', '05:00-05:30', '05:30-06:00',
      '06:00-06:30', '06:30-07:00', '07:00-07:30', '07:30-08:00', '08:00-08:30', '08:30-09:00',
      '09:00-09:30', '09:30-10:00', '10:00-10:30', '10:30-11:00', '11:00-11:30', '11:30-12:00',
      '12:00-12:30', '12:30-13:00', '13:00-13:30', '13:30-14:00', '14:00-14:30', '14:30-15:00',
      '15:00-15:30', '15:30-16:00', '16:00-16:30', '16:30-17:00', '17:00-17:30', '17:30-18:00',
      '18:00-18:30', '18:30-19:00', '19:00-19:30', '19:30-20:00', '20:00-20:30', '20:30-21:00',
      '21:00-21:30', '21:30-22:00', '22:00-22:30', '22:30-23:00', '23:00-23:30', '23:30-24:00'
    ];

    const intervalsForm = ref([]);
    const periodicity = ref(3);
    const tempIntervals = ref([]);

    const checkIntervalsAndPeriodicity = () => {
      if (intervalsForm.value.length > periodicity.value) {
        notify({
          type: 'warning',
          text: 'Количество интервалов должно соответствовать указанной частоте использования Дневника'
        })

        intervalsForm.value = intervalsForm.value.filter((interval) => tempIntervals.value.some(el => el === interval));
      }

      tempIntervals.value = intervalsForm.value;
    };

    const submitForm = () => {
      if (periodicity < 1 || periodicity > 10) {
        notify({
          type: 'warning',
          text: 'Использовать дневник можно не меньше одного и не больше десяти раз в день'
        })
      } else if (intervalsForm.value.length > periodicity.value) {
        notify({
          type: 'warning',
          text: 'Количество интервалов должно соответствовать указанной частоте использования Дневника'
        })
      } else if (intervalsForm.value.length < periodicity.value) {
        notify({
          type: 'warning',
          text: 'Укажите недостающие интервалы или измените частоту использования дневника'
        })
      } else {
        disabled.value = true;
        store.dispatch("diary/setDiarySettings", {
          intervals: intervalsForm.value,
        }).then(() => {
          disabled.value = false;
          context.emit("settingsUpdated");
        }).catch(() => {
          disabled.value = false;
        });
      }
    }

    onBeforeMount(() => {
      if (!settings.value.intervals) {
        intervalsForm.value.push('10:30-11:00', '14:00-14:30', '18:00-18:30');
      } else {
        periodicity.value = settings.value.periodicity;
        intervalsForm.value = intervalsForm.value.concat(settings.value.intervals);
      }

      tempIntervals.value = intervalsForm.value;
    });

    return {
      intervals,
      intervalsForm,
      periodicity,
      checkIntervalsAndPeriodicity,
      submitForm,
      disabled,
    }
  }
}
</script>

<style scoped lang="scss">
.button_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.options_wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 25px;
}

.settings_group {
  display: flex;
  align-items: center;
}

.form-group {
  align-items: center;
}

.form-group.intervals {
  width: 450px;
}

@media (max-width: 920px) {
  .form-group.intervals {
    width: 300px;
  }

  .settings_group {
    align-items: flex-start;
    column-gap: 15px;
  }

  .popper_wrapper {
    padding-top: 15px;
  }
}

@media (max-width: 440px) {
  .form-group.intervals {
    width: 200px;
  }

  .settings_group {
    align-items: flex-start;
    column-gap: 15px;
  }

  .popper_wrapper {
    padding-top: 15px;
  }
}

</style>