<template>
  <div v-if="(getRight('subscription') && permittedSubscriptions.length) || getRight('superadmin')" class="card">
    <div class="card-header card-header-divider header_green">
      Дневник Эмоций
    </div>
    <div class="card-body">
      <div class="info_img_wrapper">
        <img class="info_img" src="@/assets/images/diary_info_1.svg" alt="Инфографика"/>
        <img class="info_img" src="@/assets/images/diary_info_2.svg" alt="Инфографика"/>
      </div>
      <div class="tools settings">
        <div v-if="!showSettings && settings && settings.intervals">
          <p>Вы отметили, что хотели бы использовать инструмент &laquo;Дневник эмоций&raquo;
            <strong>{{ settings.periodicity }}</strong> {{ [2, 3, 4].includes(settings.periodicity) ? 'раза' : 'раз' }}
            в день в следующие временные промежутки:
            <span v-for="(interval, index) in settings.intervals" :key="index">
                <strong>{{ interval }}</strong>{{ settings.intervals.length === index + 1 ? '' : ', ' }}
            </span>.
          </p>
        </div>
        <div v-else-if="settings && !firstUsage" class="settings_header">
          <p>Пожалуйста, укажите, как часто хотите использовать Дневник</p>
        </div>
        <button v-if="!firstUsage"
                @click="showSettings = !showSettings" class="btn settings_btn"
                :class="{ active: showSettings }"
                :disabled="!settings || !settings.intervals">
            Настроить интервалы
        </button>
      </div>
      <div v-if="showSettings && !firstUsage">
        <diary-settings
            @settingsUpdated="() => {
              checkDate()
              showSettings = false;
            }"
        />
      </div>
      <div v-if="settings && settings.intervals || firstUsage" class="records_form_wrapper">
        <div class="records_control_wrapper">
          <div v-if="!diaryAvailable && nextInterval && !firstUsage" class="next_available_interval">
            <p>Следующую запись в Дневник можно будет внести в {{ nextInterval }}</p>
          </div>
          <div v-if="diaryRecords && diaryRecords.length" class="analytics_button_wrapper">
            <button class="btn btn-accent" @click="() => {
              router.push('/diary/analytics')
            }">
              Посмотреть статистику записей
            </button>
          </div>
        </div>
        <div v-if="diaryAvailable || firstUsage">
          <diary-record-form
              :availableInterval="allowedPeriod"
              :firstUsage="firstUsage"
              @recordCreated="(id) => {
                setDiaryAfterFirstRecord();
                lastCreatedRecord = id;
              }"
          />
        </div>
      </div>
      <div v-if="settings && settings.intervals">
        <div class="records" :key="lastCreatedRecord">
          <diary-records/>
        </div>
      </div>
      <div class="form-preloader" v-if="!isLoaded">
        <div>
          <mini-preloader/>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="subscription-required">
    Чтобы использовать этот инструмент, необходимо оформить подписку. Пожалуйста, сделайте это для получения доступа.
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import {onBeforeMount, ref, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import {computed} from "@vue/reactivity";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import DiarySettings from "@/views/EmotionDiary/DiarySettings.vue";
import Preloader from "@/App.vue";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";
import DiaryRecords from "@/views/EmotionDiary/DiaryRecords.vue";
import DiaryRecordForm from "@/views/EmotionDiary/DiaryRecordForm.vue";
import {useRouter} from "vue-router";
import useCheckRights from "@/mixins/useCheckRights";

export default {
  name: "EmotionDiary.vue",
  components: {DiaryRecordForm, DiaryRecords, MiniPreloader, Preloader, DiarySettings, CustomSelect, CustomInput},
  setup() {
    const {getRight} = useCheckRights();
    const user = computed(() => store.state.profile.profile);
    const router = useRouter();
    const store = useStore();
    const settings = computed(() => store.state.diary.diarySettings);
    const diaryRecords = computed(() => store.state.diary.diaryRecords);
    const isLoaded = ref(false);
    const showSettings = ref(false);
    const showAnalytics = ref(false);
    const lastCreatedRecord = ref();
    const nextInterval = ref('');
    const diaryAvailable = ref(false);
    const allowedPeriod = ref('');

    const permittedSubscriptions = computed(() => store.state.subscription.permittedSubscriptions)

    const checkDate = () => {
      const now = new Date();
      if (diaryAvailable.value && settings.value.intervals.some((el) => el === allowedPeriod.value)) {
        let periods = allowedPeriod.value.split('-');

        let endPeriod = new Date();
        let endPeriodHours = periods[1].split(':')[0];
        let endPeriodMinutes = periods[1].split(':')[1];
        endPeriod.setHours(endPeriodHours, endPeriodMinutes, 59);

        if (now > endPeriod) {
          diaryAvailable.value = false;
          let index = settings.value.intervals.indexOf(allowedPeriod.value);
          if (index + 1 < settings.value.intervals.length) {
            nextInterval.value = settings.value.intervals[index + 1].split('-')[0];
          } else {
            nextInterval.value = settings.value.intervals[0].split('-')[0];
          }
        }
      } else {
        diaryAvailable.value = false;
        let closestInterval = null;
        settings.value.intervals.map((interval) => {
          let periods = interval.split('-');

          let startPeriod = new Date();
          let startPeriodHours = periods[0].split(':')[0];
          let startPeriodMinutes = periods[0].split(':')[1];
          startPeriod.setHours(startPeriodHours, startPeriodMinutes, 0);

          let endPeriod = new Date();
          let endPeriodHours = periods[1].split(':')[0];
          let endPeriodMinutes = periods[1].split(':')[1];
          endPeriod.setHours(endPeriodHours, endPeriodMinutes, 59);

          if (now >= startPeriod && now <= endPeriod) {
            diaryAvailable.value = true
            allowedPeriod.value = interval;
          } else if (now < startPeriod) {
            if (!closestInterval) {
              closestInterval = periods[0];
            }
          }
        });

        nextInterval.value = closestInterval ? closestInterval : settings.value.intervals[0].split('-')[0];
      }
    }

    const firstUsage = ref(false);

    const setDiaryAfterFirstRecord = () => {
      store.dispatch('diary/getDiarySettings');
      showSettings.value = false;
      firstUsage.value = false;
    }

    onBeforeMount(() => {
      store.dispatch("diary/getEmotionList");
      store.dispatch('diary/getRecords');

      store.dispatch('diary/getDiarySettings').then(() => {
        isLoaded.value = true;
        if (!settings.value.intervals) {
          firstUsage.value = true;
          showSettings.value = true;
        } else {
          checkDate();
          setInterval(checkDate, 30000);
        }
      });
    });

    watch(() => user.value, () => {
      if (getRight('subscription') && user.value?.company?.id) {
        store.dispatch('subscription/getPermittedSubscriptions', user.value.company?.id);
      }
    });

    return {
      isLoaded,
      settings,
      showAnalytics,
      showSettings,
      diaryRecords,
      lastCreatedRecord,
      diaryAvailable,
      allowedPeriod,
      nextInterval,
      router,
      checkDate,
      getRight,
      permittedSubscriptions,
      firstUsage,
      setDiaryAfterFirstRecord
    }
  },
}
</script>

<style scoped lang="scss">
.header_green {
  font-size: 24px;
  font-weight: bold;
  color: #51bb50;
}

.settings {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  align-items: center;
}

.settings_btn {
  cursor: pointer;
  border: 1px solid #d4d5d5;
}

.settings_btn:hover {
  background-color: #cccccc;
}

.settings_btn.active {
  background-color: #cccccc;
}

.settings_header {
  padding-left: 10px;
  margin-right: auto;
}

.card {
  min-height: 400px;
}

.records {
  margin-top: 80px;
}

.records_form_wrapper {
  margin-top: 50px;
}

.records_control_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.records_control_wrapper:has(.next_available_interval){
  justify-content: space-between;
}

.info_img_wrapper {
  display: flex;
  margin: 15px 0;
}

.info_img {
  width: 49.4%;
}

.subscription-required {
  padding-bottom: 30px;
}

@media (max-width: 500px) {
  .records_control_wrapper {
    justify-content: space-between;
    column-gap: 15px;
  }

  .records {
    margin-top: 50px;
  }

  .settings {
    position: relative;
  }

  .settings_header {
    margin-bottom: 15px;
  }

  .settings_btn {
    position: absolute;
    top: -55px;
    right: 0;
  }

  .analytics_button_wrapper {
    margin-left: auto;
  }

  .info_img_wrapper {
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 60px;
  }

  .info_img {
    width: 100%;
  }
}
</style>